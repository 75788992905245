export const AIM_BASE_URL = "https://ams.be.thecloudworlds.com/v1";

export const AIM_SIGN_IN_ENDPOINT = AIM_BASE_URL + "/sign-in";
export const AIM_GET_GITHUB_ACCESS_TOKEN_PROXY_ENDPOINT =
  AIM_BASE_URL + "/get-github-access-token-proxy";
export const AIM_GET_GITHUB_APP_JWT_PROXY_ENDPOINT =
  AIM_BASE_URL + "/get-github-app-jwt-proxy";
export const AIM_CREATE_GIT_CLIENT_WEB_APP_ENDPOINT =
  AIM_BASE_URL + "/create-git-client-web-repo";
export const AIM_UPDATE_NETLIFY_SITE_ENDPOINT =
  AIM_BASE_URL + "/update-netlify-site";
export const AIM_UPDATE_ACCOUNT_ENDPOINT = AIM_BASE_URL + "/update-account";
export const AIM_SAVE_ORGANIZATION_ENDPOINT =
  AIM_BASE_URL + "/save-organization";
export const AIM_GET_ORGANIZATIONS_BY_EMAIL_ENDPOINT =
  AIM_BASE_URL + "/get-organizations-by-email";
export const AIM_SAVE_INFRASTRUCTURE_ENDPOINT =
  AIM_BASE_URL + "/save-infrastructure";
export const AIM_GET_INFRASTRUCTURE_BY_ORGANIZATION_ID_ENDPOINT =
  AIM_BASE_URL + "/get-infrastructure-by-organization-id";
export const AIM_GET_LINKEDIN_ACCESS_TOKEN_PROXY_ENDPOINT =
  AIM_BASE_URL + "/get-linkedin-access-token-proxy";
export const AIM_SAVE_DOCUMENTATION_SETTINGS_ENDPOINT =
  AIM_BASE_URL + "/save-documentation-settings";
export const AIM_GET_DOC_SETTINGS_BY_ORG_ID_ENDPOINT =
  AIM_BASE_URL + "/get-doc-settings-by-org-id";
export const AIM_GET_DEPLOYMENTS_BY_ORG_ID_ENDPOINT =
  AIM_BASE_URL + "/get-deployments-by-organization-id";
