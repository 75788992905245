import { Paragraph } from "../display/Paragraph";
import { ContentContainer } from "../layout/ContentContainer";
import { DocFormContainer } from "../layout/DocFormContainer";

export const AnalyticsPage = () => {
  return (
    <ContentContainer>
      <DocFormContainer title="Analytics">
        <Paragraph>Coming soon</Paragraph>
      </DocFormContainer>
    </ContentContainer>
  );
};
